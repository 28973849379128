import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from './layout'
import SEO from './seo'

import Overview from './case_study/overview'
import Section from './case_study/section'

export const pageQuery = graphql`
    query CaseStudy {
        allContentYaml {
            edges {
                node {
                    title
                    next {
                        path
                        text
                    }
                    ...Overview
                    ...Sections
                }
            }
        }
    }
`

const CaseStudy = ({ data, pageContext }) => {
    const caseStudy = data.allContentYaml.edges[pageContext.edge].node;
    const sections = caseStudy.sections.map((section, i) =>
        <Section key={"section" + i} section={section} />
    )
    return (
        <Layout>
            <SEO title={caseStudy.title} />
            <Overview overview={caseStudy.overview} title={caseStudy.title} />
            {sections}

            <div className="flex justify-center">
                <Link to={"/" + caseStudy.next.path} className="text-btn py-1 px-3 my-8 border border-current text-gondola hover:bg-au_chico hover:text-white hover:border-transparent rounded inline-flex items-center justify-center">
                    <span>{caseStudy.next.text}</span>
                </Link>
            </div>

        </Layout>
    )
}

export default CaseStudy;

import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const AtAGlance = ({ title, items }) => {
    const lis = items.map((item, i) =>
        <li
            className="my-4 sm:mr-8"
            key={title + i}>
            {item}
        </li>
    )
    return (
        <div className="sm:w-1/2">
            <h4 className="sm:mt-4 mt-8 mb-4">{title}</h4>
            <ul>
                {lis}
            </ul>
        </div>
    )
}

const ProblemStatement = ({ problem, solution }) =>
    <>
        <div className="mt-8 mb-4 text-B1 font-serif sm:mx-auto sm:w-2/3">
            <div className="inline font-bold">Problem: </div>
            <p className="inline">{problem}</p>
        </div>
        <div className="mt-8 mb-4 text-B1 font-serif sm:mx-auto sm:w-2/3">
            <div className="inline font-bold">Solution: </div>
            <p className="inline">{solution}</p>
        </div>
    </>

const Overview = ({ overview, title }) => {
    const carouselyCSS = "sm:w-1/4 sm:mx-auto sm:pl-0 sm:pb-0 w-2/3 pr-4 mb-4 flex-shrink-0";
    const images = overview.image.map((image, i) =>
        <div
            key={i}
            className={overview.image.length > 1 ? carouselyCSS : "w-full"}
            style={{ scrollSnapAlign: "start" }}>
            <Img
                loading="eager"
                fadeIn={false}
                fluid={image.childImageSharp.fluid} />
        </div>
    )

    return (
        <>
            <div
                className="mt-8 mb-0 flex w-2/3 mx-auto h-full overflow-x-scroll sm:overflow-x-hidden"
                style={{ scrollSnapType: "x mandatory" }}>
                {images}
            </div>

            <h1 className="sm:mx-auto sm:w-2/3 my-4">{title}</h1>

            <div className="sm:flex sm:mx-auto sm:w-2/3">
                <AtAGlance
                    title="Areas of Focus"
                    items={overview.areasOfFocus} />

                <AtAGlance
                    title="Important Details"
                    items={overview.importantDetails} />

            </div>

            <ProblemStatement
                problem={overview.problem}
                solution={overview.solution} />
        </>
    )
}

export const overviewFragment = graphql`
  fragment Overview on ContentYaml {
    overview {
        image {
            childImageSharp {
                fluid(maxWidth: 1280) {
                    ...GatsbyImageSharpFluid
                }
                fixed(width: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        areasOfFocus
        importantDetails
        problem
        solution
      }
  }
`

export default Overview
